import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getCollection } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const SAVED_NOTES_REQUEST = 'app/SavedNotesPage/SAVED_NOTES_REQUEST';
export const SAVED_NOTES_SUCCESS = 'app/SavedNotesPage/SAVED_NOTES_SUCCESS';
export const SAVED_NOTES_ERROR = 'app/SavedNotesPage/SAVED_NOTES_ERROR';

// ================ Reducer ================ //

const initialState = {
  savedNotes: null,
  pagination: null,
  notesListingIds: [],
  savedNotesError: null,
  savedNotesInProgress: false,
};

const resultIds = data => data.data.map(l => l.id);

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVED_NOTES_REQUEST:
      return {
        ...state,
        savedNotesInProgress: true,
        savedNotesError: null,
        savedNotes: null,
        notesListingIds: [],
      };
    case SAVED_NOTES_SUCCESS:
      return {
        ...state,
        savedNotesInProgress: false,
        savedNotes: payload.data,
        pagination: payload.meta,
        notesListingIds: resultIds(payload.listings.data)
      };
    case SAVED_NOTES_ERROR:
      return { ...state, savedNotesInProgress: false, savedNotesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const savedNoteRequest = () => ({ type: SAVED_NOTES_REQUEST });
export const savedNoteSuccess = (data, listings, meta) => ({
  type: SAVED_NOTES_SUCCESS,
  payload: { data, listings, meta }
});
export const savedNoteError = error => ({
  type: SAVED_NOTES_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const getSavedNotes = (query = {}, needRequest = true) => (dispatch, getState, sdk) => {
  const { currentUser = {} } = getState().user;
  const userId = (currentUser && currentUser.id && currentUser.id.uuid) || '';
  if (needRequest) {
    dispatch(savedNoteRequest());
  }

  const { page = 1, perPage = 4 } = query;

  return getCollection({
    tableName: "notes",
    query: {
      isActive: '1',
      userId
    },
    options: {
      sort: { createdAt: 1 },
      skip: ((page - 1) * perPage),
      limit: perPage,
    },
  })
    .then((res) => {
      const data = JSON.parse(res.data);
      return sdk.listings.query({
        // ...query,
        ids: data.map(d => d.listingId),
        include: ['author', 'images'],
        'fields.image': [
          'variants.default',
        ],
      }).then(response => {
        dispatch(addMarketplaceEntities(response));
        // res.data contains the response data
        dispatch(savedNoteSuccess(data, response, res.meta));
        return data;
      });
    })
    .catch(e => {
      dispatch(savedNoteError(e));
      // This is thrown so that form can be cleared
      // after a timeout on changePassword submit handler
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = parse(search);
  return dispatch(getSavedNotes(queryParams));
};
