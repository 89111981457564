import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { createSlug } from '../../util/urlHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import { Button, IconClose, Modal, NamedLink, ResponsiveImage } from '..';

import css from './SavedNoteCard.module.css';

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const SavedNoteCard = props => {
    const {
        intl,
        className,
        rootClassName,
        listing,
        objectId,
        description = '',
        currentUser,
        onGetSavedNotes,
        onInsertCollection,
        onManageDisableScrolling,
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [thoughts, setThoughts] = useState(description);
    const [inProgress, setInProgress] = useState(false);

    const { id, attributes, author } = listing || {};
    const { title, publicData, } = attributes || {};

    const {
        skills,
        location,
        addSelfie1,
        experiences,
        uploadVideoInPhotoes,
    } = publicData || {};

    const { displayName, publicData: APublicData } = (author && author.id && author.attributes.profile) || {};
    const {
        mainProfession,
        availableHoursPerWeek,
    } = (APublicData) || {};
    const classes = classNames(rootClassName || css.cardroot, className);

    const slug = createSlug(title);
    const yearOfExperience = experiences && experiences.length && experiences[0].inputValues
        ? moment().diff(moment(parseInt(experiences[0].inputValues.yearOfExperience.key), 'YYYY'), 'year')
        : null;

    const handleSubmitModal = (type) => {
        const data = {};
        setInProgress(type);
        if (isOpen == 'SEND') {
            // SendGrid Email to particular user
            Object.assign(data, {
                to: {
                    email,
                    name: ''
                },
                thoughts,
                profileUrl: process.env.REACT_APP_MARKETPLACE_ROOT_URL + ('/l/' + slug + '/' + id.uuid),
                listingId: id.uuid,
                createdAt: moment().toDate(),
            });
        } else {
            Object.assign(data, {
                tableName: 'notes',
                payload: {
                    listingId: id.uuid,
                    userId: currentUser.id.uuid,
                    createdAt: moment().toDate(),
                    status: 'PENDING',
                }
            });

            if (['UPDATE', 'DELETE'].includes(type)) {
                Object.assign(data, {
                    id: objectId,
                });
                if (['DELETE'].includes(type)) {
                    Object.assign(data.payload, {
                        isActive: '2',
                    });
                }
            }
        }

        return onInsertCollection(data, type).then(() => {
            if (['DELETE'].includes(type)) {
                onGetSavedNotes({}, false)
                    .then(() => [setIsOpen(false), setInProgress(false)])
            } else {
                setIsOpen(false);
                setInProgress(false);
            }
        });
    }

    return (
        <div className={classes} name="LandingPage" >
            <div className={css.root}>
                <div className={css.borderRadius}>
                    <div className={css.cardContent}>
                        <div className={css.closeIcon} onClick={() => setIsOpen('CLOSE')}>
                            <IconClose type="MultiPurposeModal" />
                        </div>
                        <div className={css.cardImg}>
                            <LazyImage
                                rootClassName={css.cardImg}
                                alt={title}
                                url={uploadVideoInPhotoes
                                    ? (uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png'))
                                    : addSelfie1}
                            />
                        </div>
                        <div className={css.cardRightContent}>
                            {displayName
                                ? <h4>{displayName.split(' ')[0]}</h4>
                                : null}
                            {experiences && experiences.length
                                ? <h5>
                                    {experiences[0].label}
                                </h5>
                                : null}
                            <ul>
                                {yearOfExperience
                                    ? <li>{yearOfExperience}{yearOfExperience > 1 ? ' years' : ' year'} experience </li>
                                    : null}
                                {availableHoursPerWeek ? <li>{availableHoursPerWeek}h per week available</li> : null}
                                <li>
                                    {location && location.timeZone
                                        ? moment().tz(location.timeZone).format('z')
                                        : moment().tz(moment.tz.guess()).format('z')} Timezone
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={css.skills}>
                        <Button
                            className={css.shareBtn}
                            onClick={() => setIsOpen('SEND')}
                        >
                            Share Note
                        </Button>
                        <Button
                            className={css.viewNoteBtn}
                            onClick={() => setIsOpen('VIEW')}
                        >
                            View Note
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                id="SavedNoteCard.ModalBox"
                isOpen={!!isOpen}
                onClose={() => setIsOpen(false)}
                onManageDisableScrolling={onManageDisableScrolling}
                className={css.shareModal}
                type="MultiPurposeModal"
            >
                <div className={css.modalImg}>
                    <div className={css.modalInnerImage}>
                        <ResponsiveImage
                            alt={title}
                            showModal={true}
                            url={uploadVideoInPhotoes
                                ? (uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png'))
                                : addSelfie1}
                        />
                    </div>
                </div>
                {isOpen == 'CLOSE'
                    ? <div className={css.modalMain}>
                        <p className={css.modalHeading}>
                            <FormattedMessage id="SavedNotesCardModal.delete" values={{ displayName: displayName.split(' ')[0] }} />
                        </p>
                        <Button
                            className={css.editNoteBtn}
                            onClick={() => setIsOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={css.sendNoteBtn}
                            onClick={() => handleSubmitModal("DELETE")}
                            inProgress={inProgress == "DELETE"}
                        >
                            Delete
                        </Button>
                    </div>
                    : <div className={css.modalMain}>
                        <p className={css.modalHeading}>
                            <FormattedMessage id="SavedNotesCardModal.title" values={{ displayName: displayName.split(' ')[0] }} />
                        </p>
                        <textarea
                            className={css.textField}
                            value={thoughts}
                            disabled={isOpen != 'UPDATE'}
                            onChange={(e) => setThoughts(e.target.value)}
                        />

                        {isOpen == "SEND"
                            ? <div className={css.emailInput}>
                                <label>Email: </label>
                                <input
                                    type='text'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            : <Button
                                className={css.editNoteBtn}
                                inProgress={inProgress == "UPDATE"}
                                onClick={() => isOpen == "UPDATE"
                                    ? handleSubmitModal("UPDATE")
                                    : setIsOpen("UPDATE")}
                            >
                                {isOpen == "UPDATE" ? "Save" : "Edit"} Note
                            </Button>}
                        <Button
                            className={css.sendNoteBtn}
                            onClick={() => {
                                isOpen == 'SEND'
                                    ? handleSubmitModal("SEND")
                                    : setIsOpen("SEND");
                            }}
                            inProgress={inProgress == "SEND"}
                            disabled={isOpen == 'SEND' && !email}
                        >
                            Send to Colleague
                        </Button>

                        <NamedLink
                            className={css.viewProfileNoteBtn}
                            name="ListingPage"
                            params={{ id: id.uuid, slug }}
                        >
                            View Profile
                        </NamedLink>

                    </div>}
            </Modal>
        </div>
    );
};

export default injectIntl(SavedNoteCard);
