import React, { useState } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { H4, Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './EarlySignupForm.module.css';

const EarlySignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        form,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        onSubmitThroughtRequest,
        mailRequest,
        isMailPending
      } = fieldRenderProps;
      const [showEmail, setshowEmail] = useState(false)

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'EarlySignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'EarlySignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'EarlySignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'EarlySignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'EarlySignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );

      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      const requestAccess =(e) =>{
        e.preventDefault()
        onSubmitThroughtRequest(values)
      }

      return (
        <>
          <Form className={classes} onSubmit={handleSubmit}>
            <H4><FormattedMessage id={"EarlySignupForm.title"} /></H4>
            {!showEmail ? <> <div>
              <FieldTextInput
                className={css.inputField}
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'EarlySignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'EarlySignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.passwordWrapper}>
                <FieldTextInput
                  className={css.passwordInputBox}
                  type={values.passwordType ? "text" : "password"}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="current-password"
                  label={intl.formatMessage({
                    id: 'EarlySignupForm.passwordLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'EarlySignupForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
                <span
                  type="button"
                  className={css.passwordShow}
                  onClick={() => form.change('passwordType', !values.passwordType)}
                >
                  {values.passwordType ? "Hide" : "Show"}
                </span>
              </div>
            </div>
              <div className={css.bottomWrapper}>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                  <FormattedMessage id="EarlySignupForm.signUp" />
                </PrimaryButton>
              </div> <div className={css.bottomWrapper}>
                OR
              </div><div className={css.bottomWrapper}>
                <PrimaryButton onClick={(e) =>{setshowEmail(true), e.preventDefault()}}  >
                  Request  Access
                </PrimaryButton>
              </div>
            </>
              : <>
                {showEmail ?
                  <div>
                    <FieldTextInput
                      className={css.inputField}
                      type="email"
                      id={formId ? `${formId}.emailId` : 'emailId'}
                      name="emailId"
                      autoComplete="email"
                      label='Email*'
                      placeholder='Enter your email..'
                      validate={validators.composeValidators(emailRequired, emailValid)}
                    />
                  </div> : null
                }
                {mailRequest ? 
                <p>*Mail has been send to the Admin</p>:null
                }
                <div className={css.bottomWrapper}>
                  <PrimaryButton onClick={(e)=>{requestAccess(e)}} inProgress={submitInProgress || isMailPending} disabled={submitDisabled || mailRequest}  >
                    Request  Access
                  </PrimaryButton>
                </div>
                <div className={css.bottomWrapper}>
                  OR
                </div>
                <div className={css.bottomWrapper}>
                  <PrimaryButton onClick={(e) => { setshowEmail(false), e.preventDefault() }}   >
                    Already Have Access
                  </PrimaryButton>
                </div>
              </>
            }
          </Form>
        </>
      );
    }}
  />
);

EarlySignupFormComponent.defaultProps = { inProgress: false };

EarlySignupFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EarlySignupForm = compose(injectIntl)(EarlySignupFormComponent);
EarlySignupForm.displayName = 'EarlySignupForm';

export default EarlySignupForm;