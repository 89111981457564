import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { searchListings } from '../SearchPage/SearchPage.duck';
export const ASSET_NAME = 'landing-page';
const RESULT_PAGE_SIZE = 10;

export const loadData = (params, search, config) => async (dispatch, getState) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
    let { currentUser } = getState().user;
    if (!currentUser || (currentUser && !currentUser.id)) {
        currentUser = await dispatch(fetchCurrentUser());
    }
    const { accountType='company' } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
    const isCompany = (accountType && accountType == 'company')  ? false : true;

  return dispatch(searchListings(
    {
      page: 1,
      pub_listingType: isCompany ? 'company' : 'fractionalPro',
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.image': [
        'varinats.default',
        'variants.scaled-small',
        'variants.scaled-medium',
      ],
      'limit.images': 1,
    },
    config
  )).then(() => {
  
    return dispatch(fetchPageAssets(pageAsset, true));
  });
};
