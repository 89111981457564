import React from 'react';

const IconInfo = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 7.9375C10.1823 7.9375 10.3572 8.00993 10.4861 8.13886C10.6151 8.2678 10.6875 8.44266 10.6875 8.625V14.8125C10.6875 14.9948 10.6151 15.1697 10.4861 15.2986C10.3572 15.4276 10.1823 15.5 10 15.5C9.81766 15.5 9.6428 15.4276 9.51386 15.2986C9.38493 15.1697 9.3125 14.9948 9.3125 14.8125V8.625C9.3125 8.44266 9.38493 8.2678 9.51386 8.13886C9.6428 8.00993 9.81766 7.9375 10 7.9375ZM10 6.5625C10.2735 6.5625 10.5358 6.45385 10.7292 6.26045C10.9226 6.06706 11.0312 5.80475 11.0312 5.53125C11.0312 5.25775 10.9226 4.99544 10.7292 4.80205C10.5358 4.60865 10.2735 4.5 10 4.5C9.7265 4.5 9.46419 4.60865 9.2708 4.80205C9.0774 4.99544 8.96875 5.25775 8.96875 5.53125C8.96875 5.80475 9.0774 6.06706 9.2708 6.26045C9.46419 6.45385 9.7265 6.5625 10 6.5625ZM0.375 10C0.375 4.68425 4.68425 0.375 10 0.375C15.3158 0.375 19.625 4.68425 19.625 10C19.625 15.3158 15.3158 19.625 10 19.625C4.68425 19.625 0.375 15.3158 0.375 10ZM10 1.75C5.44394 1.75 1.75 5.44394 1.75 10C1.75 14.5561 5.44394 18.25 10 18.25C14.5561 18.25 18.25 14.5561 18.25 10C18.25 5.44394 14.5561 1.75 10 1.75Z" fill="#E57552 " />
        </svg>
    );
};

export default IconInfo;