import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFortehWeb.module.css';

const LOCATION = 'location';

const IconFortehWeb = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case LOCATION:
      return (
        <svg className={classes} width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M19 9.754C19 17.654 12.068 22.085 10.371 23.054C10.1408 23.1855 9.85821 23.1855 9.628 23.054C7.931 22.084 1 17.652 1 9.754C1 4.78344 5.02944 0.753998 10 0.753998C14.9706 0.753998 19 4.78344 19 9.754V9.754Z" fill="#7D7D7D" stroke="#605F5D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <circle cx="10" cy="9.754" r="4.5" fill="#FEFEFE" stroke="#605F5D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

IconFortehWeb.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

IconFortehWeb.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconFortehWeb;
